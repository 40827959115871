import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const ApicoectomyPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Apicoectomy Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Apicoectomy Indianapolis IN"/>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady offers an overview of endodontic surgery. 317-846-4980" />
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady offers an overview of endodontic surgery. 317-846-4980" />
        <meta property="og:url" content="https://meridianendoindy.com/procedures/apicoectomy/" />
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/procedures/apicoectomy/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="" />
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady offers an overview of endodontic surgery. 317-846-4980" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <link rel="canonical" href="https://meridianendoindy.com/procedures/apicoectomy/" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="apicoectomy-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="apicoectomy-hero-section">
          <Container>
            <div className="col-lg-6 hero-left">
              <h1 className="hero-heading primary-text">
                Apicoectomy – An Overview Of Endodontic Surgery.
              </h1>
              <p className="hero-text">
                Generally, a root canal is all that is needed to save teeth with injured pulp from extraction. Occasionally, this non-surgical procedure will not be sufficient to heal the tooth and your endodontist will recommend surgery.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg-2"></section>
      <section id="apicoectomy-section-1">
        <Container>
            <div className="col-lg-12 apicoectomy-container">
              <div className="row">
                <div className="col-lg-8">
                  <h4>Apicoectomy – An Overview of Endodontic Surgery.</h4>
                  <p className="subtitle"><strong>Why would I need Endodontic Surgery?</strong></p>
                  <p className="apicoectomy-content">
                    Sometimes patients need more than a root canal to fully save a tooth from extraction. Our endodontist performs apicoectomies right here in our office. The procedure, also known as endodontic surgery and root-end resection, is an option to find fractures and hidden canals that don't show up on x-rays. These can still cause pain in the tooth and need repair. The doctors can also treat damaged root surfaces and the surrounding bone with an apicoectomy.
                  </p>
                </div>
                <div className="col-lg-4">
                  <iframe className="section1-video" src="https://www.youtube.com/embed/YAkBNqobqD0"></iframe>
                  <p className="text-center click-here"><a href="https://www.youtube.com/watch?v=YAkBNqobqD0" target="_blank">Click here for high-res version</a></p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                <p className="subtitle-2"><strong>What is an Apicoectomy?</strong></p>
                <p className="apicoectomy-content">
                  The procedure begins with the endodontist making an incision in the gum tissue in order to work on the bone and inflamed tissue surrounding it. Next, the damaged section is removed along with the root tip. Lastly, a root-end filling is placed to protect the root and prevent reinfection. The doctor closes up the incision with sutures. Over the next few months, the bone heals naturally. Once it's completely healed, the tooth is fully functional again.
                </p>
                <p className="apicoectomy-content">
                  To see an example of the process, we’ve included a video for you to watch on this page.
                </p>
                <p className="apicoectomy-content">
                  After the apicoectomy, patients may experience swelling and discomfort along the incision area. Although unpleasant, it's common. Your endodontist will recommend medication to take following the procedure. If the pain doesn't respond to medicine, contact Meridian Endodontics at <a href="tel:3178464980" id="tel-link">317-846-4980.</a>
                </p>
                </div>
              </div>
            </div>
        </Container>
      </section>

      <section id="apicoectomy-section-2">
        <ContactForm/>
      </section>
    </Layout>
  )
}

export default ApicoectomyPage

export const query = graphql`
  query ApicoectomyPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "apicoectomy-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
